<template>
  <v-container fluid>
    <v-card class="pa-5 mt-3">
      <v-toolbar flat>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('search')"
          outlined
          dense
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          dark
          class="mb-2"
          @click="dialog = true"
        >
          {{ $t("add") }}
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </v-toolbar>

      <data-table-component
        :headers="headers"
        :items-per-page2="10"
        :items="servers"
        :search="search"
        :loading="loading"
      />
    </v-card>

    <Dialog
      :onClose="close"
      :dialog="dialog"
      :editedItem="editedItem"
      :editedIndex="editedIndex"
      :valid="valid"
      :resetValidation="resetValidation"
      @refreshTable="refreshTable"
    />
    <confirm-dialog
      :openDialog="dialogDelete"
      :onClicked="deleteItemConfirm"
      :onClose="closeDelete"
    />
  </v-container>
</template>
<script>
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import Dialog from "./Dialog.vue";
import DataTableComponent from "../../components/DataTableComponent.vue";
export default {
  components: { DataTableComponent, ConfirmDialog, Dialog },
  data() {
    return {
      loading: true,
      valid: true,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      search: "",
      servers: [],
      editedItem: {
        id: 0,
        domain: null,
        ip: null,
        max_schools: null,
        allow_schools: null,
        comments: null
      },
      defaultItem: {
        id: 0,
        domain: null,
        ip: null,
        max_schools: null,
        allow_schools: null,
        comments: null
      },
      resetValidation: 0,
    };
  },
  computed: {
    headers() {
      var list = [
        { text: this.$t("servers.domain"), value: "domain" },
        { text: this.$t("servers.ip"), value: "ip" },
        { text: this.$t("servers.max_schools"), value: "max_schools" },
        { text: this.$t("servers.allow_schools"), value: "allow_schools",
          map: (value) => value ? 'مسموح' : 'غير مسموح'},
      ];
      var actions = {
        text: "",
        value: "actions",
        templates: [],
      };
      actions.templates.push({
        type: "btn",
        icon: "mdi-pencil",
        text: this.$t("edit"),
        click: this.editItem,
      });
      actions.templates.push({
        type: "btn",
        icon: "mdi-delete",
        text: this.$t("delete"),
        click: this.deleteItem,
        color: "red darken-2",
      });
      list.push(actions);
      return list;
    },
  },
  created() {
    if (!this.$store.getters.isInRole(101)) {
      this.$router.push({ name: "notauthorize" });
    }
    this.loading = true;
    this.refreshTable();
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.servers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.servers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      const deleteItem = this.servers[this.editedIndex];
      this.$axios
        .delete("server/" + deleteItem.id)
        .then(() => {
          this.refreshTable();
        });

      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.resetValidation += 1;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    refreshTable() {
      this.$axios
        .get("servers/", {noToast: true, failureToast: true})
        .then((response) => {
          this.servers = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
